import * as React from "react";
import {LocationProvider} from "./src/context/LocationContext";
import {LocaleProvider} from "./src/context/LocaleContext";
import {IntlProvider} from "react-intl";
import {t, translations} from "./i18n/translations";
import CookieConsent from 'react-cookie-consent';
import "prismjs/themes/prism-okaidia.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";

export const wrapPageElement = ({element, props}) => {
  const {location} = props;
  const {locale} = element.props.pageContext;

  return (
    <LocationProvider location={location}>
      <LocaleProvider locale={locale}>
        <IntlProvider messages={translations[locale]} locale={locale} defaultLocale="en">
          {element}
          <CookieConsent
            location="bottom"
            buttonText={t(`${locale}.cookie_consent_accept`)}
            cookieName="cookie-consent"
            style={{ background: "#1A2E35" }}
            buttonStyle={{ background: "#15F5CD", color:'#1A2E35', fontSize: "13px" }}
          >
            {t(`${locale}.cookie_consent`)}
          </CookieConsent>
        </IntlProvider>
      </LocaleProvider>
    </LocationProvider>
  );
};
